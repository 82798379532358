import React from "react";
import "./InformationBubble.css";
import bubble from "./images/information_bubble.png";
import Formosa from "./images/labelFormosa.png";
import Bali from "./images/labelBali.png";
import Jasmin from "./images/labelJasmin.png";
import Marocain from "./images/labelMarocain.png";
import Rio from "./images/labelRio.png";
import Limonade from "./images/labelLimonade.png";



class InformationBubble extends React.Component {
  constructor(props) {
    super(props);
    let text = "";
    let logo = "";
    switch (props.selectedProduct) {
      case "Formosa":
        text = "Pure infusion de Wulong Taïwanais. Au coeur de la fraîcheur éléphantesque sans sucre ajouté."
        logo = Formosa;
        break;
      case "Bali":
        text = "Délicate infusion de thé vert Sencha du Japon et gingembre. Parfait pour une après-midi sous le soleil de Bali.";
        logo = Bali;
        break;
      case "Jasmin":
        text = "Pure infusion de thé vert au jasmin de la province montagneuse du Fujian. Laissez-vous tenter par ses notes florales et fruitées.";
        logo = Jasmin;
        break;
      case "Marocain":
        text = "Découvrez le vrai thé marocain (thé vert gunpowder de Chine) à la menthe Nanah. Fermez les yeux et laissez-vous tenter par une balade dans le souk de la ville.";
        logo = Marocain;
        break;
      case "Rio":
        text = "Maté – Carcadé";
        logo = Rio;
        break;
      case "Limonade":
        text = "Une limonade traditionelle naturelle au pur jus de citron BIO Sicilien et son écorce infusé à froid.";
        logo = Limonade;
        break;
      default:
    }
    this.state = {
      logo: logo,
      text: text,
      opaque: false,
    };
  }
  componentDidMount(){
    console.log(this.state.uri)
    console.log('./images/label' + this.props.selectedProduct +'.png')
    this.setState({opaque: true})
  }

  


  
  render() {
    let containerClass = this.state.opaque ? "bubbleContainer opaque" : "bubbleContainer";
    return (
      <div className={this.props.display ?  "bubbleContainer opaque" : "bubbleContainer" }>
        <img className="bubble" src={bubble}>
        </img>
        <img className="bubbleLogo" src={this.state.logo}/>
        <div className="bubbleDescription">
          {this.state.text}
        </div>

      </div>
    );
  }
}
export default InformationBubble;
