import React from "react";
import "./VendingMachine.css";
import InformationBubble from "./InformationBubble.js";
import logo from "./images/logo.png";
import jasmin from "./images/bottle_jasmin.png";
import bali from "./images/bottle_bali.png";
import formosa from "./images/bottle_formosa.png";
import maroc from "./images/bottle_maroc.png";
import lemon from "./images/bottle_lemon.png";
import rio from "./images/bottle_rio.png";
import infoBox from "./images/i.png";

class VendingMachine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProduct: "",
      infoDisplayed: false,
      animate: false,
      alternator: 1, //alternator for css animation
      scale: 1.4, // use to set scale for vending machine
    };
    this.selectProduct = this.selectProduct.bind(this);
    this.addToBasket = this.addToBasket.bind(this);
    this.onHover = this.onHover.bind(this);
    this.onLeave = this.onLeave.bind(this);

  }

  selectProduct(productName) {
    for (let i = 0; i < productName.length; i++) {
      setTimeout(() => {
        this.setState({
          selectedProduct: productName.slice(0, i + 1),
        });
      }, i * 30);
    }
    //uncomment and  delete previous code to remove the text animation
    // this.setState({
    //   selectedProduct: productName
    // })
  }

  addToBasket() {
    if (this.state.selectedProduct !== "" && !this.state.animate) {
      this.setState({
        animate: true,
      });

      setTimeout(() => {
        switch (this.state.selectedProduct) {
          case "Ice Tea Formosa":
            this.props.addFactory(0)();
            break;
          case "Ice Tea Bali":
            this.props.addFactory(1)();
            break;
          case "Ice Tea Jasmin":
            this.props.addFactory(2)();
            break;
          case "Ice Tea Marocain":
            this.props.addFactory(3)();
            break;
          case "Ice Tea Rio":
            this.props.addFactory(4)();
            break;
          case "Limonade":
            this.props.addFactory(5)();
            break;
          default:
        }
        let alternator = 0;
        if (this.state.alternator === 1) {
          alternator = 2;
        } else {
          alternator = 1;
        }
        this.setState({
          animate: false,
          alternator: alternator,
        });
      }, 800);
    }
  }

  renderInfo(){
    switch (this.state.selectedProduct) {
      case "Ice Tea Formosa":
        return(
          <div className="infoContainer led1">
                <InformationBubble display={this.state.infoDisplayed}  selectedProduct={"Formosa"}/>
                <img onMouseEnter={this.onHover}
                    onMouseLeave={this.onLeave} className="infoBox" src={infoBox}></img>
              </div>
        )
        break;
      case "Ice Tea Bali":
        return(
          <div className="infoContainer led2">
                <InformationBubble  display={this.state.infoDisplayed}  selectedProduct={"Bali"}/>
                <img onMouseEnter={this.onHover}
                    onMouseLeave={this.onLeave} className="infoBox" src={infoBox}></img>
              </div>
        )
        break;
      case "Ice Tea Jasmin":
        return(
          <div className="infoContainer led3">
                <InformationBubble display={this.state.infoDisplayed}  selectedProduct={"Jasmin"}/>
                <img onMouseEnter={this.onHover}
                    onMouseLeave={this.onLeave} className="infoBox" src={infoBox}></img>
              </div>
        )
        break;
      case "Ice Tea Marocain":
        return(
          <div className="infoContainer led4">
                <InformationBubble display={this.state.infoDisplayed}  selectedProduct={"Marocain"}/>
                <img onMouseEnter={this.onHover}
                    onMouseLeave={this.onLeave} className="infoBox" src={infoBox}></img>
              </div>
        )
        break;
      case "Ice Tea Rio":
        return(
          <div className="infoContainer led5">
                <InformationBubble display={this.state.infoDisplayed}  selectedProduct={"Rio"}/>
                <img onMouseEnter={this.onHover}
                    onMouseLeave={this.onLeave} className="infoBox" src={infoBox}></img>
              </div>
        )
        break;
      case "Limonade":
        return(
          <div className="infoContainer led6">
                <InformationBubble display={this.state.infoDisplayed}  selectedProduct={"Limonade"}/>
                <img onMouseEnter={this.onHover}
                    onMouseLeave={this.onLeave} className="infoBox" src={infoBox}></img>
              </div>
        )
        break;
      default:
    }
    
  }
  onHover(){
    this.setState({infoDisplayed: true})
  }
  onLeave(){
    this.setState({infoDisplayed: false})
  }
  render() {
    return (
      <div className="container">
        <div className="vendingMachineContainer">
        

          <img alt="vitalayLogo" id="vitalayLogo" src={logo} />
          <div className="selectedProduct">
            <div className="textContainer">{this.state.selectedProduct}</div>
          </div>
          <div
            className="button"
            onClick={() => {
              this.addToBasket();
            }}
          >
            <div className="buttonText">+</div>
          </div>
          <div className="bottleArea">
          
            <img
              alt="formosa"
              className={
                "bottle formosa" +
                (this.state.selectedProduct === "Ice Tea Formosa"
                  ? " bottleSelected"
                  : "") +
                (this.state.selectedProduct === "Ice Tea Formosa" &&
                this.state.animate
                  ? " bottleDrop" + this.state.alternator
                  : "")
              }
              src={formosa}
              onClick={() => {
                this.selectProduct("Ice Tea Formosa");
              }}
            />
            <img
              alt="bali"
              className={
                "bottle bali" +
                (this.state.selectedProduct === "Ice Tea Bali"
                  ? " bottleSelected"
                  : "") +
                (this.state.selectedProduct === "Ice Tea Bali" &&
                this.state.animate
                  ? " bottleDrop" + this.state.alternator
                  : "")
              }
              src={bali}
              onClick={() => {
                this.selectProduct("Ice Tea Bali");
              }}
            />
            <img
              alt="jasmin"
              className={
                "bottle jasmin" +
                (this.state.selectedProduct === "Ice Tea Jasmin"
                  ? " bottleSelected"
                  : "") +
                (this.state.selectedProduct === "Ice Tea Jasmin" &&
                this.state.animate
                  ? " bottleDrop" + this.state.alternator
                  : "")
              }
              src={jasmin}
              onClick={() => {
                this.selectProduct("Ice Tea Jasmin");
              }}
            />
            <img
              alt="marocain"
              className={
                "bottle marocain" +
                (this.state.selectedProduct === "Ice Tea Marocain"
                  ? " bottleSelected"
                  : "") +
                (this.state.selectedProduct === "Ice Tea Marocain" &&
                this.state.animate
                  ? " bottleDrop" + this.state.alternator
                  : "")
              }
              src={maroc}
              onClick={() => {
                this.selectProduct("Ice Tea Marocain");
              }}
            />
            <img
              alt="rio"
              className={
                "bottle rio" +
                (this.state.selectedProduct === "Ice Tea Rio"
                  ? " bottleSelected"
                  : "") +
                (this.state.selectedProduct === "Ice Tea Rio" &&
                this.state.animate
                  ? " bottleDrop" + this.state.alternator
                  : "")
              }
              src={rio}
              onClick={() => {
                this.selectProduct("Ice Tea Rio");
              }}
            />
            <img
              alt="limonade"
              className={
                "bottle limonade" +
                (this.state.selectedProduct === "Limonade"
                  ? " bottleSelected"
                  : "") +
                (this.state.selectedProduct === "Limonade" && this.state.animate
                  ? " bottleDrop" + this.state.alternator
                  : "")
              }
              src={lemon}
              onClick={() => {
                this.selectProduct("Limonade");
              }}
            />
            <div
              className={
                "led led1" +
                (this.state.selectedProduct === "Ice Tea Formosa"
                  ? " ledSelected"
                  : "")
              }
            > 
            </div>
            <div
              className={
                "led led2" +
                (this.state.selectedProduct === "Ice Tea Bali"
                  ? " ledSelected"
                  : "")
              }
            />
            <div
              className={
                "led led3" +
                (this.state.selectedProduct === "Ice Tea Jasmin"
                  ? " ledSelected"
                  : "")
              }
            />
            <div
              className={
                "led led4" +
                (this.state.selectedProduct === "Ice Tea Marocain"
                  ? " ledSelected"
                  : "")
              }
            />
            <div
              className={
                "led led5" +
                (this.state.selectedProduct === "Ice Tea Rio"
                  ? " ledSelected"
                  : "")
              }
            />
            <div
              className={
                "led led6" +
                (this.state.selectedProduct === "Limonade"
                  ? " ledSelected"
                  : "")
              }
            />
          </div>
          
          {this.renderInfo()}
          <svg
            width="331px"
            height="486px"
            viewBox="0 0 331 486"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <desc>Created with Sketch.</desc>
            <defs></defs>
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g
                id="Feet"
                transform="translate(22.000000, 442.000000)"
                fill="#043443"
              >
                <polygon
                  id="Polygon-2"
                  points="27.5 0 53.6540542 16.5835921 43.6640944 43.4164079 11.3359056 43.4164079 1.3459458 16.5835921"
                ></polygon>
                <polygon
                  id="Polygon-2-Copy"
                  points="262.5 0 288.654054 16.5835921 278.664094 43.4164079 246.335906 43.4164079 236.345946 16.5835921"
                ></polygon>
              </g>
              <rect
                id="vendingMachine"
                fill="#D8D8D8"
                x="0"
                y="0"
                width="331"
                height="475"
                rx="11"
              ></rect>
              <rect
                id="top"
                fill="#47D581"
                x="0"
                y="0"
                width="331"
                height="475"
                rx="11"
              ></rect>
              <rect
                id="top-copy"
                fill="#05607C"
                x="0"
                y="420"
                width="331"
                height="55"
                rx="11"
              ></rect>
              <rect
                id="Rectangle-2"
                fill="#E7E7E7"
                x="0"
                y="46"
                width="331"
                height="394"
              ></rect>
              <rect
                id="Rectangle-3"
                fill="#47D581"
                x="245"
                y="47"
                width="86"
                height="393"
              ></rect>
              <rect
                id="Rectangle-5"
                fill="#FFFFFF"
                x="17"
                y="69"
                width="210"
                height="348"
              ></rect>
              <rect
                id="Rectangle-5-Copy"
                fill="#FFFFFF"
                transform="translate(166.000000, 23.000000) scale(1, -1) translate(-166.000000, -23.000000) "
                x="87"
                y="9"
                width="158"
                height="28"
              ></rect>
              <rect
                id="Rectangle-5-Copy-2"
                fill="#D8FAC4"
                transform="translate(166.000000, 23.000000) scale(1, -1) translate(-166.000000, -23.000000) "
                x="90"
                y="12"
                width="152"
                height="22"
              ></rect>
              <rect
                id="Rectangle"
                fill="#05607C"
                x="0"
                y="46"
                width="331"
                height="5"
              ></rect>
              <circle id="Oval" fill="#E7E7E7" cx="17" cy="457" r="4"></circle>
              <circle
                id="Oval-Copy-2"
                fill="#E7E7E7"
                cx="17"
                cy="23"
                r="4"
              ></circle>
              <circle
                id="Oval-Copy-3"
                fill="#E7E7E7"
                cx="315"
                cy="23"
                r="4"
              ></circle>
              <circle
                id="Oval-Copy-4"
                fill="#E7E7E7"
                cx="324.5"
                cy="57.5"
                r="2.5"
              ></circle>
              <circle
                id="Oval-Copy-5"
                fill="#E7E7E7"
                cx="251.5"
                cy="57.5"
                r="2.5"
              ></circle>
              <circle
                id="Oval-Copy-7"
                fill="#E7E7E7"
                cx="324.5"
                cy="433.5"
                r="2.5"
              ></circle>
              <circle
                id="Oval-Copy-6"
                fill="#E7E7E7"
                cx="251.5"
                cy="433.5"
                r="2.5"
              ></circle>
              <circle
                id="Oval-Copy"
                fill="#E7E7E7"
                cx="315"
                cy="457"
                r="4"
              ></circle>
              <rect
                id="Rectangle-6-Copy"
                fill="#E7E7E7"
                x="22"
                y="336"
                width="200"
                height="76"
              ></rect>
              <g id="Grab" transform="translate(34.000000, 346.000000)">
                <rect
                  id="Rectangle-7"
                  fill="#043443"
                  x="0"
                  y="0"
                  width="176"
                  height="61"
                  rx="11"
                ></rect>
                <rect
                  id="Rectangle-7-Copy"
                  fill="#E7E7E7"
                  x="3"
                  y="3"
                  width="170"
                  height="55"
                  rx="10"
                ></rect>
                <rect
                  id="Rectangle-7-Copy-2"
                  fill="#B6B6B6"
                  x="3"
                  y="3"
                  width="170"
                  height="33"
                  rx="9"
                ></rect>
                <rect
                  id="Rectangle-7-Copy-3"
                  fill="#E7E7E7"
                  x="3"
                  y="23"
                  width="170"
                  height="19"
                ></rect>
                <text
                  id="PUSH"
                  fontFamily="DMSans-Bold, DM Sans"
                  fontSize="15"
                  fontWeight="bold"
                  letterSpacing="-0.173750028"
                  fill="#4A4A4A"
                >
                  <tspan x="71" y="18">
                    PUSH
                  </tspan>
                </text>
              </g>
              <rect
                id="Rectangle-6"
                fill="#043443"
                x="22"
                y="74"
                width="200"
                height="262"
              ></rect>
              <g id="Row" transform="translate(22.000000, 143.000000)">
                <rect
                  id="Rectangle-8"
                  fill="#B6B6B6"
                  x="0"
                  y="0"
                  width="200"
                  height="13"
                ></rect>
                <rect
                  id="Rectangle-8-Copy"
                  fill="#05607C"
                  x="0"
                  y="3"
                  width="200"
                  height="7"
                ></rect>
              </g>
              <g id="Row-Copy" transform="translate(22.000000, 223.000000)">
                <rect
                  id="Rectangle-8"
                  fill="#B6B6B6"
                  x="0"
                  y="0"
                  width="200"
                  height="13"
                ></rect>
                <rect
                  id="Rectangle-8-Copy"
                  fill="#05607C"
                  x="0"
                  y="3"
                  width="200"
                  height="7"
                ></rect>
              </g>
              <g id="Row-Copy-2" transform="translate(22.000000, 303.000000)">
                <rect
                  id="Rectangle-8"
                  fill="#B6B6B6"
                  x="0"
                  y="0"
                  width="200"
                  height="13"
                ></rect>
                <rect
                  id="Rectangle-8-Copy"
                  fill="#05607C"
                  x="0"
                  y="3"
                  width="200"
                  height="7"
                ></rect>
              </g>
              <rect
                id="Rectangle-9-Copy"
                fill="#05607C"
                x="251"
                y="69"
                width="74"
                height="43"
                rx="8"
              ></rect>
              <g id="coin-slot" transform="translate(279.000000, 215.000000)">
                <rect
                  id="Rectangle-13"
                  fill="#E7E7E7"
                  x="0"
                  y="0"
                  width="18"
                  height="43"
                  rx="4"
                ></rect>
                <rect
                  id="Rectangle-14"
                  fill="#B6B6B6"
                  x="8"
                  y="7"
                  width="2"
                  height="29"
                ></rect>
              </g>
              <rect
                id="Rectangle-15"
                fill="#B6B6B6"
                x="263"
                y="305"
                width="50"
                height="48"
                rx="4"
              ></rect>
              <rect
                id="Rectangle-15-Copy"
                fill="#E7E7E7"
                x="266"
                y="308"
                width="44"
                height="42"
                rx="4"
              ></rect>
            </g>
          </svg>
        </div>
      </div>
    );
  }
}
export default VendingMachine;
