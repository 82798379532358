import "./Validate.css";
import { Link } from "react-router-dom";
import logo from "./images/logo.png";
import { useState } from "react";

function Validate(props) {
  const [code, setCode] = useState("");
  const [success, setSuccess] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [requestPending, setRequestPending] = useState(false);


  const logPrompt = (text) => {
    setPrompt(text);
    setTimeout(() => {
      setPrompt("");
    }, 5000);
  };

  const validate = async () => {
    if (code.length !== 5) {
      logPrompt("Veuillez entrer le code de 5 chiffres envoyé par SMS.");
      return;
    }
    try {
      if(requestPending){
        return;
      }
      setRequestPending(true);
      let url =
        "https://minimate-backend.herokuapp.com" +
        "/order/" +
        props.parentState.orderId +
        "/validate";
      let rawResponse = await fetch(url, {
        method: "put",
        body: JSON.stringify({
          validationCode: parseInt(code, 10),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      let response = await rawResponse.json();
      if (response.success) {
        setRequestPending(false);
        setSuccess(true);
      } else {
        setRequestPending(false);
        logPrompt(response.message);
      }
    } catch (err) {
      setRequestPending(false);
      logPrompt(
        "Une erreur est survenue, réessayez ou contactez notre support "
      );
    }
    // TODO Validate code via fetch API
    // clear parentState
  };
  return (
    <div className="home-background">
      <img src={logo} id="home-logo"></img>

      {!success ? (
        <h2 className="page-title" id="sms-title">
          Une dernière validation...
        </h2>
      ) : (
        <h2 className="page-title" id="sms-title">
          Merci!
        </h2>
      )}

      <div id="sms-form-holder">
        {!success ? (
          <div>
            <h5>
              Entrez le code à 5 chiffres envoyé par SMS au:{" "}
              {props.parentState.tel}
            </h5>
            <input
              value={code}
              onChange={(e) => {
                if (e.target.value.length < 6) {
                  setCode(e.target.value);
                }
              }}
              type="number"
              id="smscode"
            ></input>
            <button id="button-sms" className="primary" onClick={validate}>
              Passer la commande

            </button>
            <h5 id="sms-error">{prompt}</h5>
          </div>
        ) : (
          <h3 id="success-message">
            Merci pour votre commande! Une confirmation vous a été envoyée par
            mail à l'addresse: {props.parentState.email}
          </h3>
        )}
      </div>
    </div>
  );
}

export default Validate;
