import "./Checkout.css";
import "./Global.css";
import logo from "./images/logo.png";
import { useState } from "react";

import { useHistory } from "react-router-dom";

function Checkout(props) {
  const history = useHistory();
  const [prompt, setPrompt] = useState("");
  const [requestPending, setRequestPending] = useState(false);


  const logPrompt = (text) => {
    setPrompt(text);
    setTimeout(() => {
      setPrompt("");
    }, 5000);
  };

  let total = 0;
  const basket = props.parentState.basket.map((item) => {
    total += item.quantity * item.price;
    return (
      <tr key={item.name}>
        <td className="col1">{item.quantity} x</td>
        <td className="col2">{item.name}</td>
        <td className="col3">{(item.price * item.quantity).toFixed(2)}</td>
      </tr>
    );
  });

  let validate = async () => {

    if(requestPending){
      return;
    }
    if (props.parentState.firstName === "") {
      logPrompt("Veuillez entrer votre prénom.");
      return;
    }

    if (props.parentState.lastName === "") {
      logPrompt("Veuillez entrer votre nom.");
      return;
    }

    if (props.parentState.address === "") {
      logPrompt("Veuillez entrer votre address.");
      return;
    }

    if (props.parentState.canton === "") {
      logPrompt("Veuillez entrer votre canton.");
      return;
    }

    if (props.parentState.postal === "") {
      logPrompt("Veuillez entrer votre code postal.");
      return;
    }

    if (props.parentState.tel === "") {
      logPrompt("Veuillez entrer votre numéro de téléphone.");
      return;
    }

    if (props.parentState.email === "") {
      logPrompt("Veuillez entrer votre email.");
      return;
    }

    let count = 0;
    for (let item of props.parentState.basket) {
      count += item.quantity;
    }

    if (props.parentState.canton !== "Geneve" && count < 24) {
      logPrompt(
        "La livraisons dans le canton de " +
          props.parentState.canton +
          " est gratuite mais pour un minimum de 24 bouteilles (2 caisses). Clickez sur le boutton Retour et ajoutez les bouteilles de votre choix."
      );
      return;
    }

    let total = 0;
    let content = props.parentState.basket;
    content.map((item) => {
      return (item.subtotal = (item.price * item.quantity).toFixed(2));
    });

    props.parentState.basket.map((item) => {
      return (total = total + item.price * item.quantity);
    });
    try {
      setRequestPending(true);
      let url = "https://minimate-backend.herokuapp.com" + "/order";
      let rawResponse = await fetch(url, {
        method: "post",
        body: JSON.stringify({
          first_name: props.parentState.firstName,
          last_name: props.parentState.lastName,
          email: props.parentState.email,
          phone: props.parentState.tel.replace(" ", ""),
          content: props.parentState.basket,
          infoLivraison: props.parentState.infoLivraison,
          total_price: total.toFixed(2),
          address:
            props.parentState.address +
            " " +
            props.parentState.canton +
            " " +
            props.parentState.postal,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      let response = await rawResponse.json();
      if (response.success) {
        props.setOrderId(response.data.orderId);
        setRequestPending(false);
        history.push("/validate");
      } else {
        setRequestPending(false);
        alert("Oups, une erreur est survenue: " + response.message);
      }
    } catch (err) {
      setRequestPending(false);
      alert("Oups, une erreur est survenue: " + err.message);
    }
  };

  return (
    <div className="home-background">
      <img src={logo} id="home-logo" alt="vita-logo"></img>
      <h2 className="page-title">Finaliser votre commande</h2>
      <div id="form-holder">
        <div id="right-form">
          <div className="line-container">
            <div className="half-holder">
              <label htmlFor="prenom">Prénom</label>
              <input
                value={props.parentState.firstName}
                type="text"
                name="prenom"
                onChange={(e) => {
                  props.setFirstName(e.target.value);
                }}
              ></input>
            </div>
            <div className="half-holder">
              <label htmlFor="nom">Nom</label>
              <input
                type="text"
                name="nom"
                value={props.parentState.lastName}
                onChange={(e) => {
                  props.setLastName(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="full-holder">
            <label htmlFor="rue">Rue/num.</label>
            <input
              type="text"
              name="rue"
              value={props.parentState.address}
              onChange={(e) => {
                props.setAdrs(e.target.value);
              }}
            ></input>
          </div>
          <div className="line-container">
            <div className="half-holder">
              <label htmlFor="canton">Canton</label>
              <select
                className="half-holder"
                value={props.parentState.canton}
                onChange={(e) => {
                  props.setCanton(e.target.value);
                }}
              >
                <option value="-">Choisissez</option>
                <option value="Geneve">Genève</option>
                <option value="Vaud">Vaud</option>
                <option value="Valais">Valais</option>
                <option value="Fribourg">Fribourg</option>
                <option value="Neuchatel">Neuchâtel</option>
              </select>
            </div>
            <div className="half-holder">
              <label htmlFor="codepostal">Code Postal</label>
              <input
                type="number"
                id="postal"
                name="codepostal"
                value={props.parentState.postal}
                onChange={(e) => {
                  props.setPostal(e.target.value);
                }}
              ></input>
            </div>
          </div>
          <div className="full-holder">
            <label htmlFor="tel">Tel</label>
            <input
              placeholder="+41 79 123 4567"
              type="text"
              name="tel"
              value={props.parentState.tel}
              onChange={(e) => {
                props.setPhone(e.target.value);
              }}
            ></input>
          </div>
          <div className="full-holder">
            <label htmlFor="email">Email</label>
            <input
              placeholder="adam@eve.com"
              type="text"
              name="email"
              value={props.parentState.email}
              onChange={(e) => {
                props.setEmail(e.target.value);
              }}
            ></input>
          </div>
          <div className="full-holder">
            <label htmlFor="livraison">Informations Pour Le Livreur</label>
            <input
              placeholder="Code Bâtiment, Détails Supplémentaires"
              type="text"
              name="infoLivrason"
              value={props.parentState.infoLivraison}
              onChange={(e) => {
                props.setInfoLivraison(e.target.value);
              }}
            ></input>
          </div>
          <button
            className="secondary"
            id="back-button"
            onClick={() => history.push("/")}
          >
            ← Retour
          </button>
        </div>
        <div id="left-form">
          <div id="ticket-holder">
            <div className="order-ticket" id="checkout-ticket">
              <h3>Votre commande</h3>
              <table>
                <tbody>
                  {basket}
                  <tr id="total-line">
                    <th className="col1" id="mini-total">
                      TOTAL
                    </th>
                    <th className="col2"></th>
                    <th className="col3">{total.toFixed(2)}</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <h5 id="checkout-prompt">{prompt}</h5>
          </div>

          <button className="primary" id="order-button" onClick={validate}>
            Valider ma commande →
          </button>

          <button
            className="secondary"
            id="back-button-mobile"
            onClick={() => history.push("/")}
          >
            ← Retour
          </button>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
