import "./App.css";
import "./fonts.css";
import React from "react";
import Home from "./Home";
import Checkout from "./Checkout";
import Validate from "./Validate";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basket: [],
      email: "",
      tel: "",
      firstName: "",
      lastName: "",
      address: "",
      canton: "",
      postal: "",
      orderId: "",
      infoLivraison: ""
    };
    this.stock = [
      { name: "Formosa Ice Tea", price: 3.95 },
      { name: "Bali Ice Tea", price: 3.95 },
      { name: "Jasmin Ice Tea", price: 3.95 },
      { name: "Marocain Ice Tea", price: 3.95 },
      { name: "Ice Kaffee", price: 3.95 },
      { name: "Limonade", price: 3.95 },
    ];
  }

  async componentDidMount() {
    try {
      let url = "https://minimate-backend.herokuapp.com";
      let rawResponse = await fetch(url, {
        method: "get",
      });

      let response = await rawResponse.json();
    } catch (err) {
      console.log("Oups, une erreur est survenue: " + err.message);
    }
  }

  nameToStockIndex = (name) => {
    let index = 0;
    for (let stockItem of this.stock) {
      if (stockItem.name === name) {
        return index;
      }
      index += 1;
    }
  };

  addFactory = (stockIndex) => {
    let res = () => {
      let found = false;
      let updated = this.state.basket.map((item) => {
        if (item.name === this.stock[stockIndex].name) {
          item.quantity += 1;
          found = true;
        }
        return item;
      });
      if (!found) {
        this.setState({
          basket: [
            ...this.state.basket,
            {
              name: this.stock[stockIndex].name,
              quantity: 1,
              price: this.stock[stockIndex].price,
            },
          ],
        });
      } else {
        this.setState({
          basket: updated,
        });
      }
    };

    return res;
  };

  subFactory = (stockIndex) => {
    let res = () => {
      let updated = this.state.basket.map((item) => {
        if (item.name === this.stock[stockIndex].name) {
          item.quantity -= 1;
        }
        return item;
      });
      this.setState({
        basket: updated.filter((item) => item.quantity > 0),
      });
    };

    return res;
  };

  // adds item to the App basket state
  addItem = (stockIndex) => {
    this.setState({
      basket: [...this.state.basket, 1 + "-" + this.stock[stockIndex]],
    });
  };

  setFirstName = (firstName) => {
    this.setState({
      firstName: firstName,
    });
  };

  setLastName = (lastName) => {
    this.setState({
      lastName: lastName,
    });
  };

  setAdrs = (adrs) => {
    this.setState({
      address: adrs,
    });
  };

  setCanton = (canton) => {
    this.setState({
      canton: canton,
    });
  };

  setPostal = (postal) => {
    this.setState({
      postal: postal,
    });
  };

  setPhone = (phone) => {
    this.setState({
      tel: phone,
    });
  };

  setEmail = (email) => {
    this.setState({
      email: email,
    });
  };

  setInfoLivraison = (infoLivraison) => {
    this.setState({
      infoLivraison: infoLivraison,
    });
  };

  setOrderId = (orderId) => {
    this.setState({
      orderId: orderId,
    });
  };

  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route exact path="/">
              <Home
                addItem={this.addItem}
                basket={this.state.basket}
                addFactory={this.addFactory}
                subFactory={this.subFactory}
                nameToStockIndex={this.nameToStockIndex}
              />
            </Route>
            <Route path="/checkout">
              <Checkout
                setFirstName={this.setFirstName}
                setLastName={this.setLastName}
                setAdrs={this.setAdrs}
                setCanton={this.setCanton}
                setPostal={this.setPostal}
                setPhone={this.setPhone}
                setEmail={this.setEmail}
                setOrderId={this.setOrderId}
                setInfoLivraison={this.setInfoLivraison}
                parentState={this.state}
              />
            </Route>
            <Route path="/validate">
              <Validate parentState={this.state} />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
