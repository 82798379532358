import "./Home.css";
import "./Global.css";
import { useHistory } from "react-router-dom";
import logo from "./images/logo.png";
import { useEffect, useState } from "react";
import VendingMachine from "./VendingMachine.js";

function Home(props) {
  const history = useHistory();
  const [prompt, setPrompt] = useState("");

  const logPrompt = (text) => {
    setPrompt(text);
    setTimeout(() => {
      setPrompt("");
    }, 4000);
  };

  const nextStep = () => {
    history.push("/checkout");
  };

  const validate = () => {
    let count = 0;
    for (let item of props.basket) {
      count += item.quantity;
    }
    if (count % 12 == 0 && count != 0) {
      nextStep();
    } else {
      if (count == 0) {
        logPrompt(
          " Sélectionez la boisson de votre choix dans le mini-mate plus appuyez sur le boutton +"
        );
      } else {
        logPrompt(
          " Nous livrons uniquement en boite de 12 bouteilles, faites en sortes que le nombre total soit un multiple de 12 (12, 24, 36, ...)"
        );
      }
    }
  };

  let total = 0;

  const basket = props.basket.map((item) => {
    total += item.quantity * item.price;
    return (
      <tr key={item.name}>
        <td className="col1">{item.quantity} x</td>
        <td className="col2">{item.name}</td>
        <td className="col3">{(item.quantity * item.price).toFixed(2)}</td>
        <td className="col4">
          <button
            className="increment"
            onClick={props.addFactory(props.nameToStockIndex(item.name))}
          >
            +
          </button>
          <button
            className="increment"
            onClick={props.subFactory(props.nameToStockIndex(item.name))}
          >
            -
          </button>
        </td>
      </tr>
    );
  });
  return (
    <div className="home-background">
      <img src={logo} id="home-logo"></img>
      <div id="home-split">
        <div id="left-split">
          <h1>Vos boissons préférées, livrées chez vous.</h1>
          <h4>
            Avec le mini-mate Vitalay, commandez vos boissons préférées et
            recevez les fraichement livrées.
          </h4>
          <ul>
            <li>
              Livraison en <span className="important">boîte de 12</span>{" "}
              (sélection libre)
            </li>
            <li>
              <span className="important">Genève</span>: min. 12 bouteilles,
              livrées dans les 2 jours.
            </li>
            <li>
              <span className="important">
                Vaud, Valais, Fribourg, Neuchâtel
              </span>
              : min. 24 bouteilles, livrées dans les 7 jours.
            </li>
            <li>
              Livraison <span className="important">gratuite</span>
            </li>
          </ul>
          <div id="bill-center">
            <div className="order-ticket">
              <h3>Votre commande</h3>
              {props.basket.length > 0 ? (
                <table>
                  <tbody>
                    {basket}
                    <tr id="total-line">
                      <th className="col1" id="mini-total">
                        TOTAL
                      </th>
                      <th className="col2"></th>
                      <th className="col3">{total.toFixed(2)}</th>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <h5 id="empty-cart">
                  Utilisez le mini-mate pour commander vos produits
                </h5>
              )}
            </div>
            <button className="primary" onClick={validate}>
              Finaliser ma commande →
            </button>
            <h5 id="prompter">{prompt}</h5>
          </div>
        </div>
        <div id="right-split">
          <VendingMachine addFactory={props.addFactory} />
        </div>
      </div>
    </div>
  );
}

export default Home;
